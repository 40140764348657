import { useNavigate  } from 'react-router-dom';
import './Intro.css';
import stepOne from '../../../assets/img/gif/paso1.gif';
import videoFile from '../../../assets/video/intro.mp4';
import bot_realidad_coca from "../../../assets/img/gif/bot_realidad_coca.gif";
import bot_map from "../../../assets/img/gif/bot_mapa_1.gif";
export const Intro = () => {

    const navigate = useNavigate ();

    // Función para manejar el click del botón
    const handleCocaClick = () => {
        navigate('/phenomenon-characteristics');
    };

    const handleMapClick = () => {
        navigate('/maps');
    };

    return (
        <div className="home-container">
            {/* Intro GIF con animación */}
            <img src={stepOne} alt="Pantalla inicio animación" className="entrance-animation-intro" />

            {/* Video superpuesto */}
            <video className="overlay-video" controls autoPlay muted>
                <source src={videoFile} type="video/mp4" />
                Tu navegador no soporta el video.
            </video>

            {/* Segundo GIF que actúa como botón */}
            <img
                src={bot_realidad_coca}
                alt=""
                className="button-coca-gif"
                onClick={handleCocaClick}
            />

            {/* Tercer GIF que actúa como botón */}
            <img
                src={bot_map}
                alt=""
                className="button-map-gif"
                onClick={handleMapClick}
            />

        </div>
    )
}