import { BrowserRouter, Routes, Route } from "react-router-dom";

//Components
import { PageNotFound } from "../components/layout/page404/PageNotFound";
import { Home } from "../components/layout/home/Home";
import { Intro } from "../components/layout/intro/Intro";
import { PhenomenonCharacteristics } from "../components/layout/phenomenon-characteristics/PhenomenonCharacteristics";
import { ChangeOfExperiences } from "../components/layout/change-of-experiences/changeOfExperiences";
import { SocialChange } from "../components/layout/social-change/SocialChange";
import { ChangeInFauna } from "../components/layout/change-in-fauna/ChangeInFauna";

import { RouterEnum } from "./RouterEnum";
import {Maps} from "../components/layout/maps/Maps";


export const Routing = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Home /> } />
                <Route path={RouterEnum.Intro} element={ <Intro /> } />
                <Route path={RouterEnum.PhenomenonCharacteristics} element={ <PhenomenonCharacteristics /> } />
                <Route path={RouterEnum.ChangeExperiences} element={ <ChangeOfExperiences /> } />
                <Route path={RouterEnum.SocialChange} element={ <SocialChange /> } />
                <Route path={RouterEnum.ChangeFauna} element={ <ChangeInFauna /> } />
                <Route path={RouterEnum.Maps} element={ <Maps /> } />

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    )
}
