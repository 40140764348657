import { useNavigate } from "react-router-dom";
import './changeOfExperiences.css';
import exp1 from "../../../assets/img/gif/bot_change_exp2.gif";
import exp2 from "../../../assets/img/gif/bot_change_exp1.gif";


export const ChangeOfExperiences = () => {

    const navigate = useNavigate ();

    const handleClick = () => {
        navigate('/social-change');
    }

    return (
        <div className="home-container">
            <img src={exp1} alt=""  />

            {/* Segundo GIF que actúa como botón */}
            <img
                src={exp2}
                alt=""
                className="button-exp-gif"
                onClick={handleClick}
            />
        </div>
    )
}