import { useNavigate  } from 'react-router-dom';
import {useEffect, useRef, useState} from "react"; // Ruta del video .mp4
import './SocialChange.css';
import videoFile from '../../../assets/video/social_change.mp4';
import socialChange from "../../../assets/img/gif/bot_social_change.gif";

export const SocialChange = () => {

    const navigate = useNavigate ();
    const [showGif, setShowGif] = useState(false); // Controla si mostrar o no el GIF
    const videoRef = useRef(null); // Referencia al video

    useEffect(() => {
        const video = videoRef.current;

        const handleTimeUpdate = () => {
            if (video.currentTime >= 59) { // Al minuto de reproducción (60 segundos)
                setShowGif(true); // Muestra el GIF
            }
        };

        // Agrega el listener cuando el video se está reproduciendo
        if (video) {
            video.addEventListener('timeupdate', handleTimeUpdate);
        }

        // Limpia el listener cuando el componente se desmonte
        return () => {
            if (video) {
                video.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, []);


    // Función para manejar el click del botón
    const handleClick = () => {
        navigate('/change-fauna'); // Cambia a la ruta del siguiente componente
    };

    return (
        <div className="home-container">
            {/* Sección de video ajustada a 1080x1920 */}
            <div className="section video-section">
                <video ref={videoRef} className="video" controls autoPlay muted>
                    <source src={videoFile} type="video/mp4" />
                    Tu navegador no soporta el video.
                </video>
            </div>

            {/* Sección de GIF, aparece solo después de 60 segundos */}
            {showGif && (
                <img
                    src={socialChange}
                    alt=""
                    className="button-social-change-gif"
                    onClick={handleClick}
                />
            )}
        </div>
    )
}