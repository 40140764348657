import {useState} from "react";
import {useNavigate} from "react-router-dom";
import './Maps.css';

import imgMap from "../../../assets/img/gif/imgMapBac.gif";
import area_coca from "../../../assets/img/gif/area_coca.gif";
import bot_area_coca from "../../../assets/img/gif/bot_area_coca.gif";
import bot_area_farallones from "../../../assets/img/gif/bot_area_farallones.gif";
import area_parque from "../../../assets/img/gif/img_area_parque.gif";
import cuenca from "../../../assets/img/gif/img_cuenca_anchicaya.gif";
import restart_map from "../../../assets/img/gif/bot_restart_map.gif";
import costo_ecol from "../../../assets/img/gif/bot_costo_ecol.gif";


export const Maps = () => {

    const navigate = useNavigate();
    const [areaCoca, setAreaCoca] = useState(cuenca);

    const handleAreaCocaClick = () => {
        setAreaCoca(area_parque)
    }

    const handleAreaFarallonesClick = () => {
        setAreaCoca(area_coca)
    }

    const handleRestartClick = () => {
        navigate('/');
    }

    const handleCostClick = () => {
        navigate('/change-experiences');
    }

    return(
        <div className="home-container">
            {/* Intro GIF con animación */}
            <img src={imgMap} alt="" />

            <img
                src={areaCoca}
                alt=""
                className="area-coca-gif"
            />

            <img
                src={bot_area_coca}
                alt=""
                className="button-area-coca-gif"
                onClick={handleAreaCocaClick}
            />

            <img
                src={bot_area_farallones}
                alt=""
                className="button-area-farallones-gif"
                onClick={handleAreaFarallonesClick}
            />

            <img
                src={restart_map}
                alt=""
                className="button-back-maps-gif"
                onClick={handleRestartClick}
            />

            <img
                src={costo_ecol}
                alt=""
                className="button-cost-gif"
                onClick={handleCostClick}
            />
        </div>
    )
}