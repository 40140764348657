import { useNavigate  } from 'react-router-dom';
import './PhenomenonCharacteristics.css';
import gifFile from '../../../assets/img/gif/caracteristicas.gif';
import videoFile from '../../../assets/video/video_coca.mp4';
import bot_back from "../../../assets/img/gif/bot_back.gif";
import bot_explore_map from "../../../assets/img/gif/bot_explore_map.gif";

export const PhenomenonCharacteristics = () => {

    const navigate = useNavigate ();

    const handleBackClick = () => {
        navigate('/');
    }

    const handleExploreClick = () => {
        navigate('/maps');
    }

    return (
        <div className="home-container">
            {/* Sección de video */}
            <div className="section video-section">
                <video className="video" controls>
                    <source src={videoFile} type="video/mp4" />
                    Tu navegador no soporta el video.
                </video>
            </div>

            {/* Sección de GIF */}
            <div className="section gif-section">
                <img style={{height: '60vh'}} src={gifFile} alt="Animación GIF" className="gif" />
            </div>

            {/* Segundo GIF que actúa como botón */}
            <img
                src={bot_back}
                alt=""
                className="button-back-gif"
                onClick={handleBackClick}
            />

            {/* Tercer GIF que actúa como botón */}
            <img
                src={bot_explore_map}
                alt=""
                className="button-explore-gif"
                onClick={handleExploreClick}
            />
        </div>
    )

}