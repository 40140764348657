import { useNavigate  } from 'react-router-dom';
import './Home.css';
import gif1 from '../../../assets/img/gif/start.gif'
import gif2 from '../../../assets/img/gif/bottom_start.gif';

export const Home = () => {

    const navigate = useNavigate ();

    // Función para manejar el click del botón
    const handleClick = () => {
        navigate('/intro'); // Cambia a la ruta del siguiente componente
    };

    return (
        <div className="home-container">
            {/* Primer GIF con animación */} {/*className="entrance-animation"*/}
            <img src={gif1} alt="Pantalla inicio animación"  />

            {/* Segundo GIF que actúa como botón */}
            <img
                src={gif2}
                alt="Botón de inicio"
                className="button-gif"
                onClick={handleClick}
            />
        </div>
    );
}